.avatar-icon {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: #000;
    border:1px solid #000;
}

.modal-detail {
    label {
        font-weight: bold;
        margin-top: 15px;
    }

    p {
        opacity: .5;
        font-size: 12px;
        border-bottom: 1px solid #666;
        padding-bottom: 15px;
    }
}

.table-detail-info {
    border-collapse: collapse;
    width: 100%;

    tr td {
        border-collapse: collapse;
        text-align: left;
        border: 1px solid #ddd;
        padding: 5px;
    }

    .label {
        width: 20%;
        background-color: #efefef;
    }
}