body {
  margin: 0;
}

/*  <input type="number">のスピンボタン（上下の矢印ボタン）
    をCSSで非表示にする*/
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

table .left {
  text-align: left !important;
}

table .center {
  text-align: center !important;
}

table .right {
  text-align: right !important;
}
td.long-text-col {
  max-width: 220px;
  /* 如果超出最大宽度，则使用省略号 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td.long-text-col textarea{
  width: 220px;
  height: 30px;
}
table * {
  font-size: 12px !important;
  font-weight: bold !important;
}

table button {
  font-size: 12px !important;
  padding: 5px !important;
  height: 24px !important;
  min-width: 64px !important;

}

.text-primary {
  color: #222;
  border: 1px solid #222;
  background-color: transparent;
  display: block;
  padding:5px;
  width: 100px;
  border-radius: 6px;
  text-align: center;
}

table a {
  text-decoration: none;
  color: #1976d2;
}

.MuiPaper-elevation0 {
  background-color: #000 !important;
  color: #fff !important;
}

.MuiPaper-elevation0 * {
  color: #fff !important;
}

.MuiPaper-elevation0 .css-cveggr-MuiListItemIcon-root {
  opacity: .5;
}